/* You can add global styles to this file, and also import other style files */

html:not(.nuvo-importer-modal) .match-column-page,
html:not(.nuvo-importer-modal) .data-model-sheet-form,
html:not(.nuvo-importer-modal) .header-selection-page,
html:not(.nuvo-importer-modal) #data-model-sheet-form {
  padding-bottom: 96px !important;
}

body {
  margin: 0;
}

.container-error {
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  color: black;
}

.container-error-powered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 36px 0px 0px 0px;
}

.container-error-tag-hover {
  padding: 2px 5px 1px;
  margin: 0px 0px;
  font-size: 8px;
  text-transform: uppercase;
  background-color: #e0eafd;
  color: #3573ef;
  border-radius: 2px;
  font-weight: 500;
}

.container-error-tag-hover:hover {
  background-color: #c2d5fa;
}
